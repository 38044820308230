import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        list: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('clerks', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        show: (cntx, { id }) => {
            axios.get(`clerks/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
        },
        create: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.post('clerks', params)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        update: (cntx, params) => {
            console.log(params)
            return new Promise((resolve, reject) => {
                axios.put(`clerks/${params.id}`, params.data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        remove: (cntx, id) => {
            return new Promise((resolve, reject) => {
                axios.delete(`clerks/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        
        companies: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('companies', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        locations: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('locations', { params: params })
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
    }
}
